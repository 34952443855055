<template>
  <div class="layout">
    <TopNav
      v-if="this.showTopNav.includes(routePath)"
      :title="routeData.web_title"
      rightText=""
      rightHref=""
      :isShow="true"
      bgColor="var(--bg1)"
      textColor="#fff"
    />
    <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh"> -->
    <router-view />
    <!-- </van-pull-refresh> -->
    <van-tabbar
      v-model="active"
      v-if="
        !this.detailHide.includes(routePath) &&
        !this.routePath.includes('pointsMall')
      "
    >
      <van-tabbar-item
        :badge="item.badge ? shopCartCount : null"
        v-for="(item, index) in icon"
        :key="index + 'bar'"
        @click="jump(item)"
      >
        <span>{{ item.text }}</span>
        <template #icon="props">
          <img :src="props.active ? item.active : item.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
    <van-tabbar
      v-model="active"
      v-if="
        (this.routePath.includes('pointsMall') ||
          this.routePath.includes('user/mall-user')) &&
        !this.detailHide.includes(routePath)
      "
    >
      <van-tabbar-item
        :badge="
          item.badge ? (pointsCartCount > 0 ? pointsCartCount : null) : null
        "
        v-for="(item, index) in pointsMallIcon"
        :key="index + 'bar'"
        @click="jump(item)"
      >
        <span>{{ item.text }}</span>
        <template #icon="props">
          <img :src="props.active ? item.active : item.inactive" />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
import TopNav from "@/components/topNav.vue";
import MixinsIndex from "@/Mixin/index";
export default {
  name: "LayoutPage",
  components: {
    TopNav,
  },
  mixins: [MixinsIndex],
  data() {
    return {
      isLoading: false,
      // 显示顶部的返回
      showTopNav: [
        "/order_id",
        "/custom",
        "/guide",
        "/recommend",
        "/drawExplain",
        "/buy",
        "user/userDraw",
        "/activity",
      ],
      detailHide: [
        "/user/logistics",
        "/user/userProductAddress",
        "/pointsMall/paySuccess",
        "/activity",
        "/detail",
        "/user/login",
        "/user/regirst",
        "/user/loginCode",
        "/user/findpsw",
        "/custom",
        "/guide",
        "/user/userShare",
        "/pointsMall/detail",
        "/user/userSet",
        "/user/userOrderInfo",
        "/user/userOrderInfos",
        "/user/userOrder",
        "/user/vipOrder",
        "/user/userAddressEdit",
        "/user/userShareTo",
        "/user/userShareScore",
        "/user/userDrawTo",
        "/pointsMall/userCollect",
        "/user/infoUser",
        "/help",
        "/helpInfo",
        "/pointsMall/infoCompute",
        "/user/drawExplain",
        "/buy",
        "/user/switchUser",
        "/searchList",
        "/search",
        "/pointsMall/search",
        "/pointsMall/searchList",
        "/user/userShareRecord",
        "/user/userBalance",
        "/user/userRecharge",
        "/user/userAddress",
        "/user/userSafe",
        "/user/userSetPassword",
        "/user/userSetPayPassword",
        "/user/userSetInfo",
        "/user/userCommission",
        "/user/userWithdraw",
        "/user/userDraw",
        "/user/userBuy",
        "/user/userWithdrawBank",
        "/user/userWithdrawBankList",
        "/user/userWithdrawRecord",
        "/pointsMall/infoDetail",
        "/pointsMall/trendChart",
        "/order_id",
        "/user/userProduct",
        "/user/userGoRecord",
        "/user/userCollect",
        "/user/refundVerify",
      ],
      routeData: {},
      routePath: "",
      active: 0,
      badge: 0,
      icon: [
        {
          index: 0,
          text: "首页",
          href: "/",
          badge: "",
          active: require("@/static/images/icon_rb_home_press.png"),
          inactive: require("@/static/images/icon_rb_home_normal.png"),
        },
        {
          index: 1,
          text: "所有商品",
          href: "/category",
          badge: "",
          active: require("@/static/images/icon_rb_goods_press.png"),
          inactive: require("@/static/images/icon_rb_goods_normal.png"),
        },
        {
          index: 2,
          text: "购物车",
          href: "/cart",
          badge: "0",
          active: require("@/static/images/icon_rb_home_press.png"),
          inactive: require("@/static/images/icon_rb_home_normal.png"),
        },
        {
          index: 3,
          text: "我的",
          href: "/user/",
          badge: "",
          active: require("@/static/images/icon_rb_mine_press.png"),
          inactive: require("@/static/images/icon_rb_mine_normal.png"),
        },
      ],
      pointsMallIcon: [
        {
          index: 0,
          text: "积分商城",
          href: "/pointsMall",
          badge: "",
          active: require("@/static/images/pointsMall/icon_main_tab_home_check.png"),
          inactive: require("@/static/images/pointsMall/icon_main_tab_home_uncheck.png"),
        },
        {
          index: 1,
          text: "所有商品",
          href: "/pointsMall/category",
          badge: "",
          active: require("@/static/images/pointsMall/icon_main_tab_allgoods_check.png"),
          inactive: require("@/static/images/pointsMall/icon_main_tab_allgoods_uncheck.png"),
        },
        {
          index: 2,
          text: "最新揭晓",
          href: "/pointsMall/lottery",
          badge: "",
          active: require("@/static/images/pointsMall/icon_main_tab_newest_check.png"),
          inactive: require("@/static/images/pointsMall/icon_main_tab_newest_uncheck.png"),
        },
        {
          index: 3,
          text: "心愿单",
          href: "/pointsMall/cart",
          badge: "0",
          active: require("@/static/images/pointsMall/icon_main_tab_cart_uncheck.png"),
          inactive: require("@/static/images/pointsMall/icon_main_tab_cart_uncheck.png"),
        },
        {
          index: 4,
          text: "个人中心",
          href: "/user/mall-user",
          badge: "",
          active: require("@/static/images/pointsMall/icon_main_tab_mine_uncheck.png"),
          inactive: require("@/static/images/pointsMall/icon_main_tab_mine_uncheck.png"),
        },
      ],
    };
  },
  watch: {
    $route: {
      handler(val) {
        this.routeData = val.meta;
        this.routePath = val.path;
        this.active = val.path.includes("user") ? 4 : 0;
        let d = val.path.includes("pointsMall")
          ? this.pointsMallIcon
          : this.icon;
        const index = d.findIndex((element) => element.href === val.path);
        if (index !== -1) {
          this.active = index;
        }
      },
      deep: true,
    },
  },
  mounted() {
    // this.getCarCount();
    // this.getHttp()
  },
  methods: {
    jump(e) {
      this.$router.push({
        path: e.href,
      });
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
      }, 2000);
    },
  },
};
</script>
<style scoped>
.van-tabbar-item--active {
  color: var(--bg2);
}

.van-tabbar {
  z-index: 999;
}
</style>
