// 用户操作 登录...
const UserOperation = [
  {
    path: "login",
    meta: {
      web_title: "登录",
      key: "",
      word: "",
      login: false,
      //
      isShow: true,
      title: "",
      rightText: "注册",
      rightHref: "/user/regirst",
    },
    component: () =>
      import(
        /* webpackChunkName: "login" */ "../views/options/login/index.vue"
      ),
  },
  {
    path: "loginCode",
    name: "loginCode",
    meta: {
      web_title: "短信登录",
      key: "",
      word: "",
      login: false,
      //
      title: "",
      isShow: true,
      rightText: "注册",
      rightHref: "/user/regirst",
    },
    component: () =>
      import(
        /* webpackChunkName: "loginCode" */ "../views/options/loginCode/index.vue"
      ),
  },
  {
    path: "findpsw",
    name: "findpsw",
    meta: {
      web_title: "找回密码",
      key: "",
      word: "",
      login: false,
      //
      isShow: true,
      title: "找回密码",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "findpsw" */ "../views/options/findpsw/index.vue"
      ),
  },
  {
    path: "regirst",
    meta: {
      web_title: "注册",
      key: "",
      word: "",
      login: false,
      //
      isShow: true,
      title: "注册",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "regirst" */ "../views/options/regirst/index.vue"
      ),
  },
  {
    path: "userSetInfo",
    name: "userSetInfo",
    meta: {
      web_title: "编辑用户信息",
      key: "",
      word: "",
      login: false,
      requiresAuth: true,
      //
      isShow: false,
      title: "编辑用户信息",
      bgColor: "",
      textColor: "",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "userSetInfo" */ "../views/options/userSetInfo/index.vue"
      ),
  },
  {
    path: "userSafe",
    name: "userSafe",
    meta: {
      web_title: "账户与安全",
      key: "",
      word: "",
      login: false,
      requiresAuth: true,
      //
      isShow: true,
      title: "账户与安全",
      bgColor: "",
      textColor: "",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "userSafe" */ "../views/options/userSafe/index.vue"
      ),
  },
  {
    path: "userSetPassword",
    name: "userSetPassword",
    meta: {
      web_title: "修改密码",
      key: "",
      word: "",
      login: false,
      requiresAuth: true,
      //
      isShow: true,
      title: "修改密码",
      bgColor: "",
      textColor: "",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "userSetPassword1" */ "../views/options/userSafe/userSetPassword.vue"
      ),
  },
  {
    path: "userSetPayPassword",
    name: "userSetPayPassword",
    meta: {
      web_title: "支付密码",
      key: "",
      word: "",
      login: false,
      requiresAuth: true,
      //
      isShow: true,
      title: "支付密码",
      bgColor: "",
      textColor: "",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "userSetPayPassword" */ "../views/options/userSafe/userSetPayPassword.vue"
      ),
  },
  {
    path: "infoUser",
    name: "infoUser",
    meta: {
      web_title: "个人名片",
      key: "",
      word: "",
      login: false,
      requiresAuth: true,
      //
      isShow: true,
      title: "个人名片",
      bgColor: "var(--bg2)",
      textColor: "",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "infoUser" */ "../views/options/infoUser/index.vue"
      ),
  },
  {
    path: "switchUser",
    name: "switchUser",
    meta: {
      web_title: "切换账号",
      key: "",
      word: "",
      login: false,
      requiresAuth: true,
      //
      isShow: false,
      title: "切换账号",
      bgColor: "",
      textColor: "",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "switchUser" */ "../views/options/switchUser/index.vue"
      ),
  },
];

export default UserOperation;
