const UserOther = [
  {
    path: "/",
    meta: {
      web_title: "用户",
      key: "",
      word: "",
      login: false,
      //
      isShow: false,
      title: "",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/options/home/index.vue"),
  },
  {
    path: "userSet",
    name: "userSet",
    meta: {
      web_title: "设置",
      key: "",
      word: "",
      login: false,
      requiresAuth:true,
      //
      isShow: true,
      title: "设置",
      bgColor: "",
      textColor: "",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "userSet" */ "../views/options/userSet/index.vue"
      ),
  },
  {
    path: "userProduct",
    name: "userProduct",
    meta: {
      web_title: "换得商品",
      key: "",
      word: "",
      login: false,
      requiresAuth:true,
      //
      isShow: true,
      bgColor: "var(--bg2)",
      textColor: "#fff",
      title: "换得商品",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "userProduct" */ "../views/options/userProduct/index.vue"
      ),
  },
  {
    path: "userGoRecord",
    name: "userGoRecord",
    meta: {
      web_title: "换取商品",
      key: "",
      word: "",
      login: false,
      requiresAuth:true,
      //
      isShow: true,
      bgColor: "var(--bg2)",
      textColor: "#fff",
      title: "换取商品",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "userGoRecord" */ "../views/options/userGoRecord/index.vue"
      ),
  },
  {
    path: "userProductAddress",
    name: "userProductAddress",
    meta: {
      web_title: "订单详情",
      key: "",
      word: "",
      login: false,
      requiresAuth:true,
      isShow: true,
      bgColor: "var(--bg2)",
      textColor: "#fff",
      title: "订单详情",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "paySuccess" */ "../views/options/userProduct/userProductAddress.vue"
      ),
  },
  {
    path: "logistics",
    name: "logistics",
    meta: {
      web_title: "物流信息",
      key: "",
      word: "",
      login: false,
      requiresAuth:true,
      isShow: true,
      bgColor: "",
      textColor: "#000",
      title: "物流信息",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "paySuccess" */ "../views/options/userProduct/logistics.vue"
      ),
  },
  
  {
    path: "userCollect",
    name: "userCollect",
    meta: {
      web_title: "个人收藏",
      key: "",
      word: "",
      login: false,
      requiresAuth:true,
      //
      isShow: false,
      title: "个人收藏",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "userCollect" */ "../views/options/userCollect/index.vue"
      ),
  },
  {
    path: "drawExplain",
    name: "drawExplain",
    meta: {
      web_title: "换取说明",
      key: "",
      word: "",
      login: false,
      //
      isShow: true,
      title: "换取说明",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "drawExplain" */ "../views/options/drawExplain/index.vue"
      ),
  },
];

export default UserOther;
