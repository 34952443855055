import { get, post } from "../http";

const order = {
    getDemo: () => get("/"),
    // order/computed/:key 计算订单金额
    OrderComputed: (data) => post("/order/computed/" + data.key),
    // order/again 再次下单
    OrderAgain: (data) => post("/order/again", data),
    // order/list 订单列表
    OrderList: (data) => get("/order/list", data),
	
	// vip/list 收货商订单列表
	VipList: (data) => get("/vip/list", data),
	
	// 	// vip/list 收货商订单列表/detail 订单详情
	VipDetail: (data) => get("/vip/detail/"+data, ),
    // order/detail 订单详情
    OrderDetail: (data) => get("/order/detail/"+data, ),
    // order/create/:key 订单创建
    OrderCreate: (key,data) => post("/order/create/" + key, data),
    // order/cancel 订单取消
    OrderCancel: (data) => post("/order/cancel", data),
    // order/pay 订单支付
    OrderPay: (data) => post("/order/pay", data),
    // order/take 订单收货
    OrderTake: (data) => post("/order/take", data),
    // order/express/:uni 订单收货
    OrderExpress: (data) => post("/order/express/" + data.uni),
    // order/confirm 订单确认
    OrderConfirm: (data) => post("/order/confirm", data),
    // order/data 订单统计数据
    OrderData: (data) => post("/order/data", data),
    // 删除订单 /order/del
    delOrderData: (data) => post("/order/del", data),
    // 订单退款 refund/verify
    refundVerify: (data) => post("/order/refund/verify", data),

  };


  export default order;
