import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex) 
// 创建一个新的 Vuex store 实例
// 给我写个案例
export default new Vuex.Store({
state: {
    pointsCartCount:0,
    shopCartCount:0,
    login:{},
    shop_car:[],
    pointsMall_car:[],
    loading:false,
    timeDown:null,
    // 获取设置地址
    addressD:null,
    // 在这里定义你的状态
    info:null,
    update:false,
  },
  getters: {
    // 在这里定义你的 getters
    loading(state) {
      return state.loading;
    },
    pointsCartCount(state) {
      return state.pointsCartCount;
    },
    shopCartCount(state) {
      return state.shopCartCount;
    },
    timeDown(state) {
      return state.timeDown;
    },
    addressD(state) {
      return state.addressD;
    },
    info(state) {
      const storedInfo = window.localStorage.getItem('info');
      return storedInfo && !state.update ? JSON.parse(storedInfo) : state.info || {};
    },
  },
  mutations: {
    // 在这里定义你的 mutations
    setLoading(state, value) {
      state.loading = value;
    },
    setPointsCartCount(state, value) {
      state.pointsCartCount = value;
    },
    setShopCartCount(state, value) {
      state.shopCartCount = value;
    },
    setTimeDown(state, value) {
      state.timeDown = value;
    },
    setAddressD(state, value) {
      state.addressD = value;
    },
    setInfo(state, value) {
      state.info = value;
      state.update = true;
    },
  },
  actions: {
    // 在这里定义你的 actions
    setLoading({ commit }, value) {
      commit('setLoading', value);
    },
    setPointsCartCount({ commit }, value) {
      commit('setPointsCartCount', value);
    },
    setShopCartCount({ commit }, value) {
      commit('setShopCartCount', value);
    },
    setTimeDown({ commit }, value) {
      commit('setTimeDown', value);
    },
    setAddressD({ commit }, value) {
      commit('setAddressD', value);
    },
    setInfo({ commit }, value) {
      commit('setInfo', value);
    },
  },
  modules: {
    // 在这里定义你的 modules
  }
})

