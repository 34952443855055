import axios from "axios";
// import { Loading } from 'element-ui';
// require('dotenv').config();
import { Toast } from "vant";
// var loading = null;

// function endLoading() {
//   if(loading){
//     loading.clear();
//   }
// }
// http request 拦截器
console.log(process.env.NODE_ENV);
// 开发
if (process.env.NODE_ENV == "development") {
  axios.defaults.baseURL = "/api";
} else if (process.env.NODE_ENV == "debug") {
  axios.defaults.baseURL = "/api";
} else if (process.env.NODE_ENV == "production") {
  // 生产
  axios.defaults.baseURL = "http://m.1yyc.com/api";
}
// axios.defaults.baseURL = process.env.BASE_URL;

axios.defaults.timeout = 10000;
axios.interceptors.request.use(
  (config) => {
    config.headers = {
      "Content-Type": "multipart/form-data",
      Accept: "*/*",
      "Authori-zation": window.localStorage.getItem("user")
        ? `Bearer ${JSON.parse(window.localStorage.getItem("user")).token}`
        : null,
    };
    return config;
  },
  (error) => Promise.reject(error)
);

axios.interceptors.response.use(
  (response) => {
    // endLoading();
    if (response.data.status !== 200) {
      Toast({
        message: response.data.msg,
        onClose: function () {
          // window.location = '/'
        },
      });
      if (response.data.status > 2000) {
        window.localStorage.clear();
      }
    }
    return response.data;
  },
  (error) => {
    // endLoading();
    // Toast("您的网络不给力哦，请刷新试试");
    return new Promise(() => {
      error;
    });
  }
);
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
  return new Promise((resolve, reject) => {
    axios({
      method: "get",
      url: url,
      params: params,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}

/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params) {
  return new Promise((resolve, reject) => {
    axios
      .post(url, params)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
