import { get, post } from "../http";

const store_integral = {
  // 获取积分商场的首页商品
  getStoreIntegral: (data) => get("/store_integral/list", data),
  // 获取积分商场的首页商品
  getStoreIntegralIndex: () => get("/store_integral/index"),
  // 添加心愿单
  setLuckAdd: (data) => post("/luck/add", data),
  // 修改心愿单数量
  setLuckNum: (data) => post("/luck/num", data),
  // 获取心愿单
  getLuckList: () => get("/luck/list"),
  // 删除心愿单
  delLuckList: (data) => post("/luck/del", data),
  // 收藏
  setCollect: (data) => post("/collect/add", data),
  // 取消收藏
  delCollect: (data) => post("/collect/del", data),
  // 收藏列表
  getCollectList: (data) => get("/collect/user", data),
  // 历史兑换者 luck/record?page=1&limit=10&type=1&sid=7
  getLuckRecord: (data) => get("/luck/record", data),
  // 积分商城商品分类   intergral_category
  getIntergral_category: (data) => get("/intergral_category", data),
  // 最新揭晓
  get_lottery_list: (data) => get("/get_lottery_list", data),
  // 首页最新揭晓 get_index_lottery
  get_index_lottery: (data) => get("/index/get_index_lottery", data),
  // 马上揭晓 index/get_start_raffle_list
  get_start_raffle_list: (data) => post("/index/get_start_raffle_list", data),
  // 即将揭晓 /index/open_list
  get_open_list: (data) => get("/index/open_list", data),
  // 换取记录
  getUserBuyList: (data) => get("/getUserBuyList", data),
  // 积分详情 score/detail/7
  getDetail: (data) => get("/score/detail/" + data),
  
   // 转赠订单详情 vip/detail/7
  getVipDetail: (data) => get("/vip/detail/" + data),
  
  // 换取luck/confirm
  luckConfirm: (data) => post("/luck/confirm", data),
  // 换得商品 getUserOrderList
  getUserOrderList: (data) => get("/getUserOrderList", data),
  // 积分赠送 transfer_intergal
  transfer_intergal: (data) => post("/transfer_intergal", data),
  // 批量加入心愿单
  all_add: (data) => post("/cart/all_add", data),
  // 满员获取中奖会员
  barcoderWinlnfo: (data) => post("/barcoderWinlnfo", data),
  // history_winner 历史换得者
  getHistory_winner: (data) => get("/history_winner", data),
  // 计算结果 api/index/getCalResult
  getCalResult: (data) => post("/index/getCalResult", data),
  // lottery_going_shoplist 最新揭晓倒计时接口
  lottery_going_shoplist: (data,type) => get("/lottery_going_shoplist/" + data+'/'+type),
  // 开奖
  barcoderWinInfo: (data) => post("/barcoderWinInfo", data),
  // 获取兑换成功信息 api/luck/cart_goods
  getCart_goods: (data) => get("/luck/cart_goods", data),
  // 换得商品确认地址
  confirm_address: (data) => post("/confirm_address", data),
  // 物流 intergal/express/551?
  express: (data) => get("/intergal/express/"+ data ),
  // 确认收货 api/intergal/take_delivery/551
  take_delivery: (data) => get("intergal/take_delivery/"+ data ),
};

export default store_integral;
