<template>
  <div id="app">
    <keep-alive>
      <Layout/>
    </keep-alive>
  </div>
</template>
<script>
import Layout from './components/layout.vue'
export default{
  components:{
    Layout,
  }
}

</script>
<style lang="scss">

#app {
  font-family:PingFangSC-Regular,Helvetica Neue,Helvetica,STHeiTi,sans-serif;
  // font-family: 'Helvetica Neue',Helvetica,'PingFang SC','Hiragino Sans GB','Microsoft YaHei','微软雅黑',Arial,sans-serif;
}

</style>
