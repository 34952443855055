<template>
  <div class="topNav">
    <van-sticky>
      <van-nav-bar
        :style="{
          background: bgColor,
          color: textColor,
          '--txtcolor': textColor,
        }"
        v-if="isShow"
        :title="title"
        :right-text="rightText"
        left-arrow
        @click-left="onClickLeft"
        @click-right="onClickRight(rightHref)"
      />
    </van-sticky>
  </div>
</template>
<script>
export default {
  name: "topNav",
  props: {
    title: {
      type: String,
      default: "",
    },
    bgColor: {
      type: String,
      default: "#FF6D33",
    },
    textColor: {
      type: String,
      default: "#fff",
    },
    rightText: {
      type: String,
      default: "",
    },
    rightHref: {
      type: String,
      default: "",
    },
    isShow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show:false
    };
  },
  mounted() {
    this.routrData = this.$route.meta;
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onClickRight(href) {
      this.$router.push(href);
    },
  },
  watch: {
    $route: {
      handler(val) {
        this.routrData = val.meta;
      },
      deep: true,
    },
  },
};
</script>
<style lang="scss">
.topNav {
  .van-nav-bar .van-icon {
    font-size: 0.42rem;
  }
  .van-nav-bar__title,
  .van-nav-bar .van-icon {
    color: var(--txtColor);
  }
  [class*="van-hairline"]::after {
    border: none;
  }
}
</style>
