
import { get, post } from "../http";

const cart = {
    // /cart/list 购物车列表
    getCartList: (data) => get("/cart/list", data),
    // api/cart/add 购物车添加
    setCartAdd: (data) => post("/cart/add", data),
    // api/cart/count 购物车获取数量
    getCartCount: (data) => get("/cart/count", data),
    // 购物车删除
    setCartDel: (data) => post("/cart/del", data),
    // 购物车修改数量
    setCartNum: (data) => post(`/cart/num`,data),
    // 购物车删除
    getCarCount: () => get(`/cart/count`),
    // 批量加入心愿单 cart/all_add
    setAllAdd: (data) => post(`/cart/all_add`,data),
      // 购物车数量
    getLuckCount: () => get("/luck/count"),
  };

export default cart;
