import UserOperation from "./userOperation";
import OrderOperation from "./orderOperation";
import AddressOperation from "./addressOperation";
import AmountOperation from "./amountOperation";
import vipOperation from "./vipOperation";
import UserOther from "./userOther";


const User = [
  {
    path: "/user",
    name: "user",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/options/index.vue"),
    children: [
      // 用户其他
      ...UserOther,
      // 用户管理
      ...UserOperation,
      // 金额
      ...AmountOperation,
      // 订单
      ...OrderOperation,
	  // 回收商
	  ...vipOperation,
      //地址
      ...AddressOperation,
      // 积分商城个人中心
      {
        path: "mall-user",
        name: "mall-user",
        meta: {
          web_title: "个人中心",
          key: "",
          word: "",
          login: false,
          //
          isShow: false,
          title: "个人中心",
          bgColor: "",
          textColor: "",
          rightText: "",
          rightHref: "",
        },
        component: () =>
          import(
            /* webpackChunkName: "user" */ "../views/options/home/mall-user.vue"
          ),
      },
    ],
  },
];

export default User;
