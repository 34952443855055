const pointsMall = [{
	path: "/pointsMall",
	name: "pointsMall",
	component: function() {
		return import(
			/* webpackChunkName: "pointsMall" */
			"../pointsMall/index.vue"
		);
	},
	children: [{
			path: "/",
			meta: {
				web_title: "积分商城",
				key: "",
				word: "",
				login: false,
				// 首页积分特殊
				point_home: true,
				//
				isShow: false,
				title: "",
				rightText: "",
				rightHref: "",
			},
			component: function() {
				return import(
					/* webpackChunkName: "home" */
					"../pointsMall/home/index.vue"
				);
			},
		},
		{
			path: "lottery",
			name: "lottery",
			meta: {
				web_title: "最新揭晓",
				key: "",
				word: "",
				login: false,
				//
				isShow: true,
				title: "最新揭晓",
				rightText: "",
				rightHref: "",
			},
			component: () =>
				import(
					/* webpackChunkName: "lottery" */
					"../pointsMall/lottery/index.vue"
				),
		},
		{
			path: "category",
			name: "category",
			meta: {
				web_title: "商品分类",
				key: "",
				word: "",
				login: false,
				//
				isShow: true,
				title: "商品分类",
				rightText: "",
				rightHref: "",
			},
			component: () =>
				import(
					/* webpackChunkName: "category" */
					"../pointsMall/category/index.vue"
				),
		},
		{
			path: "cart",
			name: "cart",
			meta: {
				web_title: "心愿单",
				key: "",
				word: "",
				requiresAuth: true,
				login: false,
				//
				isShow: true,
				title: "心愿单",
				rightText: "",
				rightHref: "",
			},
			component: () =>
				import( /* webpackChunkName: "cart" */ "../pointsMall/cart/index.vue"),
		},
		{
			path: "detail",
			name: "detail",
			meta: {
				web_title: "商品详情",
				key: "",
				word: "",
				login: false,
				//
				isShow: false,
				title: "商品详情",
				rightText: "",
				rightHref: "",
			},
			component: () =>
				import(
					/* webpackChunkName: "detail" */
					"../pointsMall/detail/index.vue"
				),
		},
		{
			path: "infoDetail",
			name: "infoDetail",
			meta: {
				web_title: "图文详情",
				key: "",
				word: "",
				login: false,
				//
				isShow: false,
				title: "图文详情",
				rightText: "",
				rightHref: "",
			},
			component: () =>
				import(
					/* webpackChunkName: "infoDetail" */
					"../pointsMall/detail/infoDetail.vue"
				),
		},
		{
			path: "detailIndex",
			name: "detailIndex",
			meta: {
				web_title: "走势图",
				key: "",
				word: "",
				login: false,
				//
				isShow: false,
				title: "走势图",
				rightText: "",
				rightHref: "",
			},
			component: () =>
				import(
					/* webpackChunkName: "infoDetail" */
					"../pointsMall/detailIndex/detailIndex.vue"
				),
		},
		{
			path: "trendChart",
			name: "trendChart",
			meta: {
				web_title: "走势图",
				key: "",
				word: "",
				login: false,
				//
				isShow: false,
				title: "走势图",
				rightText: "",
				rightHref: "",
			},
			component: () =>
				import(
					/* webpackChunkName: "infoDetail" */
					"../pointsMall/trendChart/index.vue"
				),
		},
		{
			path: "infoCompute",
			name: "infoCompute",
			meta: {
				web_title: "计算详情",
				key: "",
				word: "",
				login: false,
				//
				isShow: false,
				title: "计算详情",
				rightText: "",
				rightHref: "",
			},
			component: () =>
				import(
					/* webpackChunkName: "infoCompute" */
					"../pointsMall/infoCompute/index.vue"
				),
		},
		{
			path: "recommend",
			name: "recommend",
			meta: {
				web_title: "分类",
				key: "",
				word: "",
				login: false,
				//
				isShow: true,
				title: "分类",
				rightText: "",
				rightHref: "",
			},
			component: () =>
				import(
					/* webpackChunkName: "recommend" */
					"../pointsMall/recommend/index.vue"
				),
		},
		{
			path: "userCollect",
			name: "userCollect",
			meta: {
				web_title: "个人收藏",
				key: "",
				word: "",
				requiresAuth: true,
				login: false,
				//
				isShow: true,
				title: "个人收藏",
				rightText: "",
				rightHref: "",
			},
			component: () =>
				import(
					/* webpackChunkName: "userCollect" */
					"../pointsMall/userCollect/index.vue"
				),
		},
		{
			path: "search",
			name: "pointsMall_search",
			meta: {
				web_title: "搜索",
				key: "",
				word: "",
				login: false,
			},
			component: () =>
				import(
					/* webpackChunkName: "pointsMall_search" */
					"../pointsMall/search/index.vue"
				),
		},
		{
			path: "searchList",
			name: "pointsMall_searchList",
			meta: {
				web_title: "搜索",
				key: "",
				word: "",
				login: false,
			},
			component: () =>
				import(
					/* webpackChunkName: "pointsMall_searchList" */
					"../pointsMall/searchList/index.vue"
				),
		},
		{
			path: "paySuccess",
			name: "paySuccess",
			meta: {
				web_title: "换取结果",
				key: "",
				word: "",
				login: false,
			},
			component: () =>
				import(
					/* webpackChunkName: "paySuccess" */
					"../pointsMall/paySuccess/index.vue"
				),
		},
	],
}, ];
export default pointsMall;
