const other = [
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/",
    name: "home",
    meta: {
      web_title: "首页",
      key: "",
      word: "",
      login: false,
    },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/home/index.vue"),
  },
  {
    path: "/guide",
    name: "guide",
    meta: {
      web_title: "新手指南",
      key: "",
      word: "",
      login: false,
    },
    component: () =>
      import(/* webpackChunkName: "guide" */ "../views/guide/index.vue"),
  },
  {
    path: "/help",
    name: "help",
    meta: {
      web_title: "帮助",
      key: "",
      word: "",
      login: false,
    },
    component: () =>
      import(/* webpackChunkName: "help" */ "../views/help/index.vue"),
  },
  {
    path: "/helpInfo",
    name: "helpInfo",
    meta: {
      web_title: "帮助中心",
      key: "",
      word: "",
      login: false,
    },
    component: () =>
      import(/* webpackChunkName: "helpInfo" */ "../views/help/helpInfo.vue"),
  },
  {
    path: "/sharegoods",
    name: "sharegoods",
    meta: {
      web_title: "分享",
      key: "",
      word: "",
      login: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "sharegoods" */ "../views/sharegoods/index.vue"
      ),
  },
  {
    path: "/detail",
    name: "detail",
    meta: {
      web_title: "详情",
      key: "",
      word: "",
      login: false,
    },
    component: () =>
      import(/* webpackChunkName: "detail" */ "../views/detail/index.vue"),
  },

  {
    path: "/order_id",
    name: "order_id",
    meta: {
      web_title: "支付订单",
      key: "",
      word: "",
      requiresAuth:true,
      login: false,
      //
      isShow: true,
      title: "支付订单",
      rightText: "",
      rightHref: "",
      bgColor: "var(--bg2)",
      textColor: "#fff",
    },
    component: () =>
      import(/* webpackChunkName: "order_id" */ "../views/buy/order_id.vue"),
  },
  {
    path: "/search",
    name: "search",
    meta: {
      web_title: "搜索",
      key: "",
      word: "",
      login: false,
    },
    component: () =>
      import(/* webpackChunkName: "search" */ "../views/search/index.vue"),
  },
  {
    path: "/searchList",
    name: "searchList",
    meta: {
      web_title: "搜索",
      key: "",
      word: "",
      login: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "searchList" */ "../views/searchList/index.vue"
      ),
  },
  {
    path: "/category",
    name: "category",
    meta: {
      web_title: "所有商品",
      key: "",
      word: "",
      login: false,
    },
    component: () =>
      import(/* webpackChunkName: "category" */ "../views/category/index.vue"),
  },
  {
    path: "/cart",
    name: "cart",
    meta: {
      web_title: "购物车",
      key: "",
      word: "",
      requiresAuth:true,
      login: false,
    },
    component: () =>
      import(/* webpackChunkName: "cart" */ "../views/cart/index.vue"),
  },
  {
    path: "/recommend",
    name: "recommend",
    meta: {
      web_title: "专区",
      key: "",
      word: "",
      login: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "recommend" */ "../views/recommend/index.vue"
      ),
  },
  {
    path: "/activity",
    name: "activity",
    meta: {
      web_title: "购物新体验",
      key: "",
      word: "",
      login: false,
    },
    component: () =>
      import(/* webpackChunkName: "activity" */ "../views/activity/index.vue"),
  },
  {
    path: "/custom",
    name: "custom",
    meta: {
      web_title: "好物定制",
      key: "",
      word: "",
      login: false,
    },
    component: () =>
      import(/* webpackChunkName: "custom" */ "../views/custom/index.vue"),
  },
  {
    path: "/buy",
    name: "buy",
    meta: {
      web_title: "确认订单",
      key: "",
      word: "",
      requiresAuth:true,
      login: false,
    },
    component: () =>
      import(/* webpackChunkName: "buy" */ "../views/buy/index.vue"),
  },
];

export default other;
