import api from "../request/api";
import { mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      // 手机号
      account: "",
      // 密码
      password: "",
      // 推广编号
      spread: "",
      // 验证码
      captcha: "",
      // 是否成功发送短信
      isSuccessCaptcha: false,
      // 用户
      userData: {},
      // pay_open
      payType: "alipay",
      // 地址列表
      address: [],
      // 默认地址ID
      is_default_id: null,
      // 备用
      is_default_id_: null,
      // 地址
      addressData: null,
      // 默认地址——
      addressData_: null,
      // 验证码倒计时 60
      codeTime: 60,
      // 定时器
      timer: null,
      // 是否发送
      isSendCode: null,
      status: 1,
      // 发送倒计时提示
      countdown: null,
      // 头像
      avatarImage: null,
      // 同意协议
      is_agreement: false,

      d: {
        "address[province]": "",
        "address[city]": "",
        "address[district]": "",
        "address[city_id]": "",
        detail: "",
        is_default: false,
        real_name: "",
        phone: "",
        id: 0,
      },
      // 购物车数量
      // shopCartCount:0,
      // 心愿单数量
      // pointsCartCount:0,
      // 未登录头像
      nologin: require("@/static/images/nologin.png"),
      // 登录初始化头像
      ologin: require("@/static/images/login.png"),
      // 备份地址
      addressB: {
        province: "",
        city: "",
        district: "",
      },
      // 协议弹窗
      agreement: false,
    };
  },
  computed: {
    ...mapState(["pointsCartCount", "shopCartCount"]),
  },
  mounted() {
    this.getHttpCartCount();
  },
  methods: {
    ...mapActions(["setPointsCartCount", "setShopCartCount", "setInfo"]),
    async login() {
      if (!(this.account || this.account != "")) {
        this.$toast("请填写手机号码");
        return false;
      }
      if (this.account.length != 11) {
        this.$toast("手机号码格式错误");
        return false;
      }

      try {
        const result = await api.login({
          account: this.account,
          password: this.password,
        });
        if (result.status === 200) {
          window.localStorage.setItem("user", JSON.stringify(result.data));
        }
        var this_ = this;
        this.$toast({
          message: result.status === 200 ? "登录成功" : result.msg,
          duration: 1000,
          onClose: function () {
            if (result.status === 200) {
              this_.getUserinfo();
              this_.$router.push({
                path: "/user/",
              });
            } else if (result.status == 202) {
              this_.$router.push({
                path: "/user/regirst",
              });
            }
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    islogin(data) {
      let is_login = false;
      // const info = window.localStorage.getItem("info") ? JSON.parse(
      //   window.localStorage.getItem("info")
      // ) : null;
      const useList = window.localStorage.getItem("userList")
        ? JSON.parse(window.localStorage.getItem("userList"))
        : null;
      useList.forEach((item) => {
        item.phone == data.account ? (is_login = true) : "";
      });
      if (is_login) {
        return {
          data: "请勿重复登录，如需登录请在设置中切换账户。",
          status: 300,
        };
      } else {
        return {
          data: "可以登录",
          status: 200,
        };
      }
    },
    async regirst() {
      try {
        if (!this.is_agreement) {
          this.$toast("请同意服务器协议");
          return false;
        }

        const result = await api.register({
          account: this.account,
          password: this.password,
          captcha: this.captcha,
          spread: this.$route.query.spread,
        });
        if (result.status == 200) {
          this.login();
          // this.$router.push({path:'/user'})
        }
        this.$toast(result.msg);
      } catch (error) {
        console.error(error);
      }
    },
    // 获取 发短信key
    async verify_code(callback) {
      try {
        const result = await api.verify_code();
        // console.log(callback)
        callback(result);
        if (result.status !== 200) {
          this.$toast(result.msg);
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 短信登录
    async isReg(phone, type) {
      try {
        const result = await api.isReg({
          phone,
          type,
        });
        this.status = 1;
        if (result.status === 402) {
          this.$toast(result.msg);
        } else {
          var this_ = this;
          this.verify_code(function (res) {
            if (res.status === 200) {
              this_.sendCode({
                key: res.data.key,
                type,
              });
            } else {
              this.$toast(res.msg);
            }
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 发送验证码
    sendCode({ key, type }) {
      this.isSendCode = true;
      var this_ = this;
      console.log({
        key,
        type,
      });

      this.getCodeTime(this.codeTime, async function (e) {
        if ((e.status == 200 && e.text == 0) || !this_.countdown) {
          await api
            .registerVerify({
              phone: this_.account,
              type,
              key,
            })
            .then((result) => {
              if (result.status == 200) {
                this_.isSendCode = true;
              }
              this_.$toast(result.msg);
            })
            .catch((err) => {
              console.error(err);
            });
        } else {
          // this.$toast('');
        }
      });
    },
    // 发送验证码
    registerCode({ type }) {
      this.codeTime = 60;
      this.countdown = null;
      var this_ = this;
      if (!this.isSendCode) {
        let isPhone = new RegExp("^1[345789][0-90{9}$]", "i");

        if (!(this.account || this.account != "")) {
          this.$toast("请填写手机号码");
          return false;
        }
        if (!isPhone.test(this.account || this.account.length != 11)) {
          this.$toast("手机号码格式错误");
          return false;
        }
        if (this.account.length != 11) {
          this.$toast("手机号码格式错误");
          return false;
        }
        if (this.password.length < 8) {
          this.$toast("密码强度不够！");
          return false;
        }
        this_.isReg(this_.account, type);
        // this.verify_code(function(res) {
        // 	if (res.status === 200) {
        // 		if(type=='regirst' || type=='mobile'){
        // 			this_.isReg(this_.account,type);
        // 		}

        // 		if(this_.isSendCode!=false){
        // 		this_.sendCode({
        // 			key: res.data.key,
        // 			type
        // 		});
        // 		}

        // 	}
        // });
      } else {
        this.$toast("请勿重复发送短信");
      }
    },
    // 调用是否登录时间
    getCodeTime(time, callback) {
      var t = time;
      this.timer = setInterval(() => {
        if (t <= 0) {
          this.isSendCode = null;
          clearInterval(this.timer);
          callback({
            status: 200,
            text: t + "秒后再发",
          });
          this.countdown = {
            status: 200,
            text: t + "秒后再发",
          };
        } else {
          t--;
          callback({
            status: 400,
            text: t + "秒后再发",
          });
          this.countdown = {
            status: 400,
            text: t + "秒后再发",
          };
        }
      }, 1000);
    },
    // 短信验证登录
    getLoginMobileCode({ type }) {
      this.codeTime = 60;
      this.countdown = null;
      var this_ = this;
      let isPhone = new RegExp("^1[345789][0-90{9}$]", "i");

      if (!(this.account || this.account != "")) {
        this.$toast("请填写手机号码");
        return false;
      }
      if (!isPhone.test(this.account)) {
        this.$toast("手机号码格式错误");
        return false;
      }
      if (this.account.length != 11) {
        this.$toast("手机号码格式错误");
        return false;
      }
      let data = new FormData();
      data.append("phone", this.account);
      data.append("captcha", this.captcha);
      data.append("spread", this.spread);
      // this.isReg(this.account,type);

      // this.isReg(this_.account,type);
      this.verify_code(function (res) {
        if (res.status === 200) {
          this_.sendCode({
            key: res.data.key,
            type,
          });
        } else {
          this.$toast(res.msg);
        }
      });
    },
    // 短信登录
    async loginMobile() {
      try {
        const result = await api.loginMobile({
          phone: this.account,
          captcha: this.captcha,
          spread: this.spread,
        });
        if (result.status === 200) {
          window.localStorage.setItem("user", JSON.stringify(result.data));
        }
        var this_ = this;
        this.$toast({
          message: result.status === 200 ? "登录成功" : result.msg,
          duration: 1000,
          onClose: function () {
            if (result.status === 200) {
              this_.getUserinfo();
              this_.$router.push({
                path: "/user",
              });
            }
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    // 找回密码发送验证码
    findPswCode({ type }) {
      this.registerCode({
        type,
      });
    },
    async resetPsw(phone) {
      try {
        const result = await api.resetPsw({
          account: this.account,
          captcha: this.captcha,
          password: this.password,
          phone,
        });
        this.$toast(result.msg);
        if (result.status == 200) {
          window.localStorage.clear();
          this.$router.push({
            path: "/user/login",
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 验证是佛发送短信
    isSendCaptcha(fn) {
      if (this.captcha == "") {
        this.$toast("请先填写验证码");
      } else {
        fn();
      }
    },
    isSendCaptcha_(fn) {
      if (!this.is_agreement) {
        this.$toast("请阅读协议");
        this.agreement = true;
        return false;
      } else if (this.captcha == "") {
        this.$toast("请先填写验证码");
      } else {
        fn();
      }
    },
    findpsw() {
      this.$toast("登录成功" + this.account);
    },
    // 获取用户信息
    async getUserinfo() {
      try {
        const result = await api.getUserinfo();
        if (result.status === 200) {
          let is_user = false;
          this.userData = result.data;
          if (result.data.pay_open == 1) {
            this.payType = "alipay";
          } else {
            this.payType = "yue";
          }
          console.log(result.data);
          window.localStorage.setItem("info", JSON.stringify(result.data));
          this.setInfo(result.data);
          if (window.localStorage.getItem("userList")) {
            const useList = JSON.parse(window.localStorage.getItem("userList"));
            useList.forEach((item) => {
              item.uid == result.data.uid ? (is_user = true) : "";
            });
            if (!is_user) {
              useList.push(result.data);
              window.localStorage.setItem("userList", JSON.stringify(useList));
            }
          } else {
            window.localStorage.setItem(
              "userList",
              JSON.stringify([result.data])
            );
          }
        } else {
          this.$toast("请登录");
          window.localStorage.clear();
          this.$router.push({
            path: "/user/login",
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 上传图片
    uploadImage(data) {
      try {
        api
          .uploadImage(data)
          .then((result) => {
            this.$toast(result.msg);
            if (result.status == 200) this.avatarImage = result.data.url;
          })
          .catch((err) => {
            throw err;
          });
      } catch (error) {
        console.error(error);
      }
    },
    // 获取地址列表
    async getAddressList(data) {
      try {
        const result = await api.address.getAddressList(data);
        if (result.status === 200) {
          this.address = result.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 保存地址
    async saveAddress(data) {
      try {
        const result = await api.address.setAddress(data);
        if (result.status === 200) {
          // window.close();
          this.$toast({
            message: data.id == 0 || !data.id ? "添加成功" : "编辑成功",
            duration: 1000,
            onClose: function () {
               window.location.replace("#" + "/user/userAddress");
            },
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 获取默认地址
    async getAddressDefault() {
      try {
        const result = await api.address.getAddressDefault();
        if (result.status === 200) {
          this.addressData_ = result.data;
          this.is_default_id = result.data.id;
          this.is_default_id_ = result.data.id;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 删除地址
    delAddress(data, index) {
      try {
        this.$dialog
          .confirm({
            title: `是否删除该地址？`,
            confirmButtonColor: "var(--bg1)",
            message: "删除后，将无法恢复该地址。",
          })
          .then(async () => {
            const result = await api.address.delAddress(data);
            if (result.status === 200) {
              this.$toast("删除成功");
              this.address.splice(index, 1);
            } else {
              this.$toast("删除失败");
            }
          })
          .catch(() => {
            // on cancel
            this.$toast("取消删除");
          });
      } catch (error) {
        console.error(error);
      }
    },
    // 设置默认地址
    async setAddressDefault(id) {
      if (!id) return false;
      try {
        const result = await api.address.setAddressDefault({
          id,
        });
        this.$toast(result.status === 200 ? "设置成功" : "设置失败");
        if (result.status === 200) {
          if (this.$route.query?.detail == 1) {
            // 返回上一次
            window.history.back();
          }
          this.is_default_id = id;
        } else {
          this.is_default_id = this.is_default_id_;
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 获取单个地址
    async getAddressDetail(id) {
      if (!id) return false;
      try {
        const result = await api.address.getAddressDetail(id);
        if (result.status === 200) {
          this.d = result.data;
          this.addressB = result.data;
          this.d["address[province]"] = result.data.province;
          this.d["address[city]"] = result.data.city;
          this.d["address[district]"] = result.data.district;
          this.d["address[city_id]"] = result.data.id;
        }
      } catch (error) {
        this.$toast("错误的地址id");
        this.$router.push({
          path: "/user/userAddress",
        });
        console.error(error);
      }
    },
    // 退出登录
    logout() {
      try {
        this.$dialog
          .confirm({
            title: `是否退出登录？`,
            confirmButtonColor: "var(--bg1)",
          })
          .then(async () => {
            // on confirm
            await api
              .logout()
              .then((result) => {
                if (result.status === 200) {
                  this.$toast("退出成功");
                  this.setPointsCartCount(null);
                  this.setShopCartCount(null);
                  this.$router.go(-1);
                } else {
                  this.$toast("退出失败");
                }
              })
              .catch((err) => {
                throw err;
              });
          })
          .catch(() => {
            // on cancel
            this.$toast("取消登录");
          });
      } catch (error) {
        console.error(error);
      }
    },
    // 修改用户信息
    async userEdit(data) {
      try {
        const result = await api.userEdit({
          avatar: this.avatarImage,
          nickname: data.nickname,
          addres: data.addres,
          sex: data.sex,
        });
        this.$toast(result.status === 200 ? "保存成功" : "保存失败");
        if (result.status === 200) {
          window.localStorage.setItem("info", JSON.stringify(data));
        }
      } catch (error) {
        console.error(error);
      }
    },
    // 获取购物车数量
    async getHttpCartCount() {
      try {
        if (
          window.localStorage.getItem("user") &&
          window.localStorage.getItem("info")
        ) {
          const [carCount, PointsCartCount] = await Promise.all([
            api.cart.getCarCount(),
            api.cart.getLuckCount(),
          ]);
          if (PointsCartCount.status == 200) {
            this.setPointsCartCount(PointsCartCount.data.count || null);
          }
          if (carCount.status == 200) {
            this.setShopCartCount(carCount.data.count || null);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
};
