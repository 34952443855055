import Vue from "vue";
import VueRouter from "vue-router";
import other from "./other";
import pointsMall from "./pointsMall";
import User from "./user";
import api from "@/request/api";
Vue.use(VueRouter);
const routes = [
  // 单页面
  ...other,
  // 用户操作
  ...User,
  // 积分商城
  ...pointsMall,
  // 全匹配
  {
    path: "*",
    name: "NonexistentRoute",
    component: () => import("@/views/NonexistentView.vue"),
  },
];
const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
router.afterEach(() => {
  document
    .querySelector("body")
    .setAttribute("style", "overflow: auto !important;");
  window.scrollTo(0, 0);
});
const f7b844 = [
  "/user/mall-user",
  "/user/infoUser",
  "/user/userProduct",
  "/user/userCollect",
  "/user/userGoRecord",
  "/user/userDraw",
  "/user/userBalance",
];
const ff = [
  "/user/userShareScore",
  "/user/userShareTo",
  "/user/userDrawTo",
  "/user/userCommission",
  "/user/userAddress",
  "/help",
  "/user/userAddressEdit",
  "/helpInfo",
  "/user/userSet",
  "/user/userSetInfo",
  "/user/userCommission",
  "/user/userOrder",
];
router.beforeEach((to, from, next) => {
  console.log(to.path);
  if (to.path.includes("pointsMall")) {
    document
      .querySelectorAll('meta[name="theme-color"]')
      .forEach(function (meta) {
        meta.setAttribute("content", "#f7b844");
      });
  } else {
    if (ff.includes(to.path)) {
      document
        .querySelectorAll('meta[name="theme-color"]')
        .forEach(function (meta) {
          meta.setAttribute("content", "#fff");
        });
    }else if (f7b844.includes(to.path)) {
      document
        .querySelectorAll('meta[name="theme-color"]')
        .forEach(function (meta) {
          meta.setAttribute("content", "#f7b844");
        });
    } else if (to.path.includes("user/userShare")) {
      document
        .querySelectorAll('meta[name="theme-color"]')
        .forEach(function (meta) {
          meta.setAttribute("content", "#ff4630");
        });
    } else {
      document
        .querySelectorAll('meta[name="theme-color"]')
        .forEach(function (meta) {
          meta.setAttribute("content", "#FF8746");
        });
    }
  }
  if (to.meta.content) {
    let head = document.getElementsByTagName("head");
    let meta = document.createElement("meta");
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", to.meta.content.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", to.meta.content.description);
    document
      .querySelector('meta[property="description"]')
      .setAttribute("content", to.meta.content.description);
    meta.content = to.meta.content;
    head[0].appendChild(meta);
  }
  if (to.meta.web_title) {
    document
      .querySelector('meta[property="title"]')
      .setAttribute("content", to.meta.web_title);
    try {
      api
        .getConfig()
        .then((result) => {
          if (result.status === 200) {
            document.title = result.data.name + "-" + to.meta.web_title;
          } else {
            document.title = "云小云-" + to.meta.web_title;
          }
          console.log(result.msg);
        })
        .catch((err) => {
          throw err;
        });
    } catch (error) {
      console.error(error);
    }
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // 如果需要身份验证，可以在这里执行一些检查，例如检查用户是否已登录
    const isAuthenticated = checkIfUserIsAuthenticated(); // 这里是一个示例的检查方法
    if (!isAuthenticated) {
      next("/user/login");
    } else {
      next();
    }
  } else {
    next();
  }
  next();
});

function checkIfUserIsAuthenticated() {
  const isAuthenticated =
    window.localStorage.getItem("user") && window.localStorage.getItem("info")
      ? true
      : false;
  return isAuthenticated;
}

export default router;
