// 订单
const OrderOperation = [
  {
    path: "userOrder",
    meta: {
      web_title: "商城订单",
      key: "",
      word: "",
      requiresAuth: true,
      login: false,
      //
      isShow: true,
      title: "商城订单",
      bgColor: "",
      textColor: "",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "userOrder" */ "../views/options/userOrder/index.vue"
      ),
  },
  {
    path: "userOrderInfo",
    name: "userOrderInfo",
    meta: {
      web_title: "订单详情",
      key: "",
      word: "",
      requiresAuth: true,
      login: false,
      //
      isShow: true,
      title: "订单详情",
      bgColor: "",
      textColor: "",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "userOrderInfo" */ "../views/options/userOrder/userOrderInfo.vue"
      ),
  },
  {
    path: "refundVerify",
    name: "refundVerify",
    meta: {
      web_title: "订单退款",
      key: "",
      word: "",
      requiresAuth: true,
      login: false,
      //
      isShow: true,
      title: "订单退款",
      bgColor: "",
      textColor: "",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "refundVerify" */ "../views/options/userOrder/refundVerify.vue"
      ),
  },
];

export default OrderOperation;
