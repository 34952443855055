import { get, post } from "../http";
const bank = {
    // /cart/edit 添加银行卡
    setBankEdit: (data) => post("/bank/edit", data),
    // /cart/list 购物车列表
    getBankList: (data) => get("/bank/list", data),
    // 获取默认银行
    getBankDefault: (data) => get("/bank/default", data),
    // 设置默认银行
    setBankDefault: (data) => post("/bank/default/set", data),
    // 设置默认银行
    delBankDel: (data) => post("/bank/del", data),
    
  };

export default bank;
