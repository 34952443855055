import { get, post } from "../http";

const address = {
  // address/default 获取默认地址
  getAddressDefault: () => get("/address/default"),
  // 地址列表
  getAddressList: (data) => get("/address/list" , data),
  //   获取单个地址
  getAddressDetail: (data) => get("/address/detail/" + data),
  // 设置默认地址
  setAddressDefault: (data) => post("/address/default/set", data),
  // 删除地址
  delAddress: (data) => post("/address/del", data),
  // 修改或者添加地址 0 是添加地址
  setAddress: (data) => post("/address/edit", data),
};

export default address;
