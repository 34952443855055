// 用户操作 登录...
const AddressOperation = [
  {
    path: "userAddress",
    name: "userAddress",
    meta: {
      web_title: "我的收货地址",
      key: "",
      word: "",
      requiresAuth: true,
      login: false,
      //
      isShow: true,
      title: "我的收货地址",
      bgColor: "",
      textColor: "",
      rightText: "添加",
      type: "_blank",
      rightHref: "/user/userAddressEdit",
    },
    component: () =>
      import(
        /* webpackChunkName: "userAddress" */ "../views/options/userAddress/index.vue"
      ),
  },
  {
    path: "userAddressEdit",
    name: "userAddressEdit",
    meta: {
      web_title: "添加收货地址",
      key: "",
      word: "",
      requiresAuth: true,
      login: false,
      //
      isShow: true,
      title: "添加收货地址",
      bgColor: "",
      textColor: "",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "userAddress" */ "../views/options/userAddress/userAddressEdit.vue"
      ),
  },
];

export default AddressOperation;
