var baseSize = 85,uiWidth = 637.5;
// 设置 rem 函数
function setRem() {
  const scale = document.documentElement.clientWidth / uiWidth;
  // 设置页面根节点字体大小
//  console.log(document.documentElement.clientWidth)
  document.documentElement.style.fontSize =
    baseSize * scale + "px";
    // document.documentElement.style.fontSize='55.2px'
    if(document.documentElement.clientWidth < uiWidth){
        document.querySelector('#app').style.width = 'auto';
        document.querySelector('body').classList.remove('pc')
    }else{
        document.documentElement.style.fontSize = "55.2px";
        document.querySelector('body').classList.add('pc')
    }
}
setRem();

// setTimeout(()=>{
//     setRem();
// },1000)

// window.onload=function(){
// // 初始化
//     setRem();
// }

// 改变窗口大小时重新设置 rem
window.onresize = function () {
    setRem();
};
