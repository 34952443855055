import Vue from "vue";
import App from "./App.vue";
import Vant from "vant";
import "./registerServiceWorker";
import store from "./store";
import router from "./routers";
import "./utils/rem";
import "vant/lib/index.css";
import VueCountdownTimer from '@/components/lib/vue-countdown-timer';
import "./app.css";
Vue.use(VueCountdownTimer);
Vue.use(Vant);
Vue.use(VueLazyload, {
  preLoad: 1,
  error: require("./static/images/icon_wechat_share_default.png"),
  loading: require("./static/images/icon_wechat_share_default.png"),
  attempt: 2,
});
import VueLazyload from 'vue-lazyload'
Vue.use(VueLazyload)
Vue.component("VueCountdownTimer", VueCountdownTimer);
Vue.directive('lazy', VueLazyload.directive)
Vue.prototype.$routerBack = function (_, _type = "back", _path = "") {
  if (_type == "jump") {
    _.$router.push(_path);
  } else {
    _.$router.go("-1");
  }
};
// Vue.filter("filterTime", function (timestamp) {
//   const date = new Date(timestamp);
//   const year = date.getFullYear();
//   const month = (date.getMonth() + 1).toString().padStart(2, "0"); // 月份从 0 开始，需要加 1，并且保证两位数
//   const day = date.getDate().toString().padStart(2, "0"); // 天数，保证两位数
//   const hours = date.getHours().toString().padStart(2, "0"); // 小时，保证两位数
//   const minutes = date.getMinutes().toString().padStart(2, "0"); // 分钟，保证两位数
//   const seconds = date.getSeconds().toString().padStart(2, "0"); // 秒，保证两位数
//   const milliseconds = date.getMilliseconds().toString().padStart(3, "0"); // 毫秒，保证三位数
//   const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}.${milliseconds}`;
//   return formattedDate;
// });
Vue.filter("dateFormat", function (dateStr, time) {
  //得到特定的时间
  var date = new Date(dateStr);
  var year = date.getFullYear();
  //ES6语法填充：var month = (date.getMonth()+1).toString().padStart(2, '0');
  var month =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
  var hh = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
  var mm = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
  var ss = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();

  if (time && time.toLowerCase() === "yyyy-mm-dd") {
    return `${year}-${month}-${day}`;
  } else if (time && time.toLowerCase() === "hh:mm:ss") {
    return `${hh}:${mm}:${ss}`;
  }else if (time && time.toLowerCase() === "mm-dd hh:mm") {
    return `${month}月${day}日 ${hh}:${mm}`;
  } else {
    return `${year}-${month}-${day} ${hh}:${mm}:${ss}`;
  }
});

Vue.filter("timestampToTime", function (timestamp) {
  // 将秒级时间戳转换为毫秒级时间戳
  const timestampInMilliseconds = timestamp * 1000;
  
  // 创建一个 Date 对象
  const date = new Date(timestampInMilliseconds);

  // 提取日期和时间组件
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  // 返回格式化后的日期和时间
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
});

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
