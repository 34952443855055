const AmountOperation = [
  {
    path: "userShare",
    meta: {
      web_title: "邀请返现",
      key: "",
      word: "",
      login: false,
      requiresAuth: true,
      //
      isShow: true,
      title: "邀请返现",
      bgColor: "rgb(255, 70, 48)",
      textColor: "#fff",
      rightText: "返现管理",
      rightHref: "/user/userCommission",
    },
    component: () =>
      import(
        /* webpackChunkName: "userShare" */ "../views/options/userShare/index.vue"
      ),
  },
  {
    path: "userShareRecord",
    meta: {
      web_title: "我的邀请",
      key: "",
      word: "",
      login: false,
      requiresAuth: true,

      //
      isShow: true,
      title: "我的邀请",
      bgColor: "var(--bg2)",
      textColor: "#fff",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "userShareRecord" */ "../views/options/userShareRecord/index.vue"
      ),
  },
  {
    path: "userBalance",
    name: "userBalance",
    meta: {
      web_title: "账户余额",
      key: "",
      word: "",
      login: false,
      requiresAuth: true,

      //
      isShow: true,
      title: "账户余额",
      bgColor: "var(--bg2)",
      textColor: "#fff",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "userShareRecord" */ "../views/options/userBalance/index.vue"
      ),
  },
  {
    path: "userRecharge",
    name: "userRecharge",
    meta: {
      web_title: "充值",
      key: "",
      word: "",
      login: false,
      requiresAuth: true,

      //
      isShow: true,
      title: "充值",
      bgColor: "var(--bg2)",
      textColor: "#fff",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "userRecharge" */ "../views/options/userBalance/userRecharge.vue"
      ),
  },
  {
    path: "userBuy",
    name: "userBuy",
    meta: {
      web_title: "获得积分",
      key: "",
      word: "",
      login: false,
      requiresAuth: true,

      //
      isShow: true,
      title: "获得积分",
      bgColor: "var(--bg2)",
      textColor: "#fff",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "userBuy" */ "../views/options/userBalance/userBuy.vue"
      ),
  },
  {
    path: "userShareTo",
    name: "userShareTo",
    meta: {
      web_title: "返现转账",
      key: "",
      word: "",
      login: false,
      requiresAuth: true,

      //
      isShow: true,
      title: "返现转账",
      bgColor: "",
      textColor: "",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "user" */ "../views/options/userShareTo/index.vue"
      ),
  },
  {
    path: "userShareScore",
    name: "userShareScore",
    meta: {
      web_title: "兑换积分",
      key: "",
      word: "",
      login: false,
      requiresAuth: true,

      //
      isShow: true,
      title: "兑换积分",
      bgColor: "",
      textColor: "",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "userShareScore" */ "../views/options/userShareScore/index.vue"
      ),
  },
  {
    path: "userDrawTo",
    name: "userDrawTo",
    meta: {
      web_title: "积分赠送",
      key: "",
      word: "",
      login: false,
      requiresAuth: true,

      //
      isShow: true,
      title: "积分赠送",
      bgColor: "",
      textColor: "",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "userDrawTo" */ "../views/options/userDrawTo/index.vue"
      ),
  },
  {
    path: "userDraw",
    name: "userDraw",
    meta: {
      web_title: "我的积分",
      key: "",
      word: "",
      login: false,
      requiresAuth: true,
      //
      isShow: false,
      title: "我的积分",
      bgColor: "",
      textColor: "",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "userDraw" */ "../views/options/userDrawTo/userDraw.vue"
      ),
  },
  {
    path: "userWithdrawRecord",
    name: "userWithdrawRecord",
    meta: {
      web_title: "提现明细",
      key: "",
      word: "",
      login: false,
      requiresAuth: true,

      //
      isShow: true,
      title: "提现明细",
      bgColor: "var(--bg2)",
      textColor: "",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "userWithdrawRecord" */ "../views/options/userDrawTo/userWithdrawRecord.vue"
      ),
  },
  {
    path: "userCommission",
    name: "userCommission",
    meta: {
      web_title: "我的返现",
      key: "",
      word: "",
      requiresAuth: true,
      login: false,
      //
      isShow: true,
      title: "我的返现",
      rightText: "提现",
      rightHref: "/user/userWithdraw",
    },
    component: () =>
      import(
        /* webpackChunkName: "userCommission" */ "../views/options/userCommission/index.vue"
      ),
  },
  {
    path: "userWithdrawBank",
    name: "userWithdrawBank",
    meta: {
      web_title: "添加提现方式",
      key: "",
      word: "",
      requiresAuth: true,
      login: false,
      //
      isShow: true,
      title: "添加提现方式",
      rightText: "",
      rightHref: "",
      bgColor: "var(--bg2)",
      textColor: "#fff",
    },
    component: () =>
      import(
        /* webpackChunkName: "userWithdrawBank" */ "../views/options/userWithdraw/userWithdrawBank.vue"
      ),
  },
  {
    path: "userWithdraw",
    name: "userWithdraw",
    meta: {
      web_title: "提现申请",
      key: "",
      word: "",
      requiresAuth: true,
      login: false,
      //
      isShow: true,
      title: "提现申请",
      rightText: "",
      rightHref: "",
    },
    component: () =>
      import(
        /* webpackChunkName: "userWithdraw" */ "../views/options/userWithdraw/index.vue"
      ),
  },
  {
    path: "userWithdrawBankList",
    name: "userWithdrawBankList",
    meta: {
      web_title: "管理提现账户",
      key: "",
      word: "",
      requiresAuth: true,
      login: false,
      //
      isShow: true,
      title: "管理提现账户",
      rightText: "",
      rightHref: "",
      bgColor: "var(--bg2)",
      textColor: "#fff",
    },
    component: () =>
      import(
        /* webpackChunkName: "userWithdrawBankList" */ "../views/options/userWithdraw/userWithdrawBankList.vue"
      ),
  },
];

export default AmountOperation;
