var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"layout"},[(this.showTopNav.includes(_vm.routePath))?_c('TopNav',{attrs:{"title":_vm.routeData.web_title,"rightText":"","rightHref":"","isShow":true,"bgColor":"var(--bg1)","textColor":"#fff"}}):_vm._e(),_c('router-view'),(
      !this.detailHide.includes(_vm.routePath) &&
      !this.routePath.includes('pointsMall')
    )?_c('van-tabbar',{model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l((_vm.icon),function(item,index){return _c('van-tabbar-item',{key:index + 'bar',attrs:{"badge":item.badge ? _vm.shopCartCount : null},on:{"click":function($event){return _vm.jump(item)}},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active ? item.active : item.inactive}})]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.text))])])}),1):_vm._e(),(
      (this.routePath.includes('pointsMall') ||
        this.routePath.includes('user/mall-user')) &&
      !this.detailHide.includes(_vm.routePath)
    )?_c('van-tabbar',{model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},_vm._l((_vm.pointsMallIcon),function(item,index){return _c('van-tabbar-item',{key:index + 'bar',attrs:{"badge":item.badge ? (_vm.pointsCartCount > 0 ? _vm.pointsCartCount : null) : null},on:{"click":function($event){return _vm.jump(item)}},scopedSlots:_vm._u([{key:"icon",fn:function(props){return [_c('img',{attrs:{"src":props.active ? item.active : item.inactive}})]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.text))])])}),1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }