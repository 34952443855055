import { get, post } from "./http";
import cart from './model/cart'
import order from './model/order'
import address from './model/address'
import store_integral from './model/store_integral'
import bank from './model/bank'

// 定义一个对象，用来存放所有的接口
const api = {
  initFrontIndex: (data) => get("/initFrontIndex", data),
  login: (data) => post("/login", data),
  register: (data) => post("/register", data),
  // 获取发送验证码key
  verify_code: () => get("/verify_code"),
  // 发送验证码
  registerVerify: (data) => post("/register/verify", data),
  // 短信登录
  loginMobile: (data) => post("/login/mobile", data),
  // 修改密码
  resetPsw: (data) => post("/register/reset", data),
  // 用户修改信息
  userEdit: (data) => post("/user/edit", data),
  
  // 用户修改信息
  isReg: (data) => post("/register/is_reg", data),
  // 获取 帮助 信息
  getHelp: () => get("/help"),
  // 获取 帮助 信息
  getConfig: () => get("/config"),
  
  // 获取 帮助 信息
  getService: () => get("/service"),
  
  // 热门搜索 /search/keyword
  getHotSearch: () => get("/search/keyword"),
  
  // 热门搜索 /search/keyword
  getIntergalHotSearch: () => get("/intergal_search/keyword"),
  // 获取首页信息 index
  getIndexData: () => get("/index"),
  // 个人信息
  getUserinfo: () => get("/userinfo"),
  // /spread/banner 获取分销二维码海报生成
  getSpreadBanner: () => get("/spread/banner "),
  // 推广佣金明细 spread/commission
  getSpreadCommission: (data) => get("/spread/commission/0?"+data),
  // 体现 extract/cash
  extractCash: (data) => post("/extract/cash", data),
  // 购物车接口 cart
  cart,
  // 订单接口 order
  order,
  // 地址
  address,
  // 获取积分商场
  store_integral,
  // 银行卡
  bank,
  // 上传图片 
  uploadImage: (data) => post("/upload/image", data),
  // 产品详情 /api/product/detail/1
  getProductDetail: (data) => get("/product/detail/"+data),
  // 订单详情 order/detail/:uni?uni=wx2019052910013710014
  getOrderDetail: (data) => get("/order/detail/?uni"+data),
  // 兑换积分 redeem_points
  redeem_points: (data) => post("/redeem_points", data),
  // 转账 transfer_money
  transfer_money: (data) => post("/transfer_money", data),
  // 商品分类 category
  getCategory: (data) => get("/category", data),
  // 分类商品列表 products
  getProducts: (data) => get("/products", data),
  // 反佣金http://7.telegram-3.top/api/spread/commission/0?page=1&limit=20
  getCommission: (data) => get("/spread/commission/"+data, ),
  // 为我推荐product/hot
  getProductHot: (data) => get("/product/hot", data),
  // 定制 /product/custom
  getProductCustom: (data) => get("/product/custom", data),
  // 我的积分
  getIntegralList: (data) => get("/integral/list", data),

  logout:() => get("/logout"),
  // 充值列表recharge/index
  rechargeIndex: (data) => get("/recharge/index", data),
  // 充值接口 
  rechargeThird: (data) => post("/recharge/third", data),
  // 邮费 order/postage_pay
  postage_pay: (data) => post("/order/postage_pay", data),
  // spread/people 邀请好友
  spreadPeople: (data) => post("/spread/people", data),
  // 累计返现 /commission
  getCommissionL: () => get("/commission"),
  // uid 获取用户
  getUserinfo_s: (data) => post("/getUserinfo",data),
};




// 导出 api 对象
export default api;
